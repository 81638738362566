@import 'src/ui-kit/styles/colors';

.payer-data {
  display: flex;
  flex-direction: column;
  margin: 30px 0;

  &__container {
    margin-bottom: 30px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &--label {
    font-family: var(--elo-font-sans-serif);
    font-weight: 500;
    font-size: var(--elo-font-size-sm);
    color: $elo-grey-dark;
    padding-bottom: 4px;
  }

  &--text {
    font-family: var(--elo-font-sans-serif);
    font-weight: 500;
    font-size: 14px;
    color: $elo-black-semi;
  }

  @media (max-width: 991px) {
    margin-bottom: 0;
  }
}
