@import 'src/ui-kit/styles/colors';

.invoice-banner {
  margin-bottom: 20px;
  padding: 30px;
  background-color: $elo-white;
  border: 1px solid $elo-grey-40;

  &__title {
    display: flex;
    flex-wrap: wrap;
    align-items: baseline;
    font-family: var(--elo-font-sans-serif);
    font-weight: 500;
    margin-bottom: 2px;

    .order-text {
      font-size: var(--elo-font-size-lg);
      color: $elo-grey;
      margin-right: 5px;
    }

    .order-id {
      font-size: var(--elo-font-size-lg);
      color: $elo-blue;
      margin-right: 5px;

      &:hover {
        color: $elo-blue-60;
        text-decoration: none;
      }
    }

    .order-from {
      font-family: var(--elo-font-sans-serif);
      font-weight: 400;
      font-size: var(--elo-font-size-sm);
      color: $elo-grey-dark;
    }

    @media (max-width: 576px) {
      margin-bottom: 10px;
    }
  }

  &__message {
    font-family: var(--elo-font-sans-serif);
    font-size: var(--elo-font-size-sm);
    color: $elo-grey-dark;
  }
}
