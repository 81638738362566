.session-timer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100px;
  margin-bottom: 20px;
  border-radius: 5px;

  div:last-child {
    width: 100px;
    margin-top: 1px;
  }

  span {
    font-size: var(--elo-font-size-lg);
    font-family: var(--elo-font-sans-serif);

    &:first-child {
      margin-left: 12px;
    }
  }
}
