.new-summary-prices {
  margin-bottom: 24px;

  &__prices-info {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
  }

  &__total {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 8px;

    &--label {
      font: var(--elo-body-xs-regular);
      color: var(--color-primary-dg-400);
    }
  }

  &__total-today {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &--label {
      font: var(--elo-body-sm-medium);
      color: var(--color-primary-dg-brand);
    }
  }

  &__vat,
  &__fees {
    font: var(--elo-body-xs-regular);
    color: var(--color-primary-dg-400);
    margin-top: 8px;
    text-align: right;
  }

  &__vat-text,
  &__reverse-vat-text {
    font: var(--elo-body-xs-regular);
    color: var(--color-primary-dg-400);
    margin-top: 8px;
  }

  &__coupon {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    column-gap: 8px;
    margin-bottom: 8px;

    &--applied {
      border: 1px solid var(--color-highlight-web);

      & > span {
        font: var(--elo-body-xs-regular);
        color: var(--color-primary-neutral-black);
      }
    }

    &--label {
      font: var(--elo-body-xs-regular);
      color: var(--color-primary-dg-brand);
    }
  }

  &__details-link {
    text-align: right;
  }
}

.new-summary-prices-modal {
  &__product {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;
    column-gap: 8px;
  }

  &__today,
  &__test-period,
  &__custom-date,
  &__subtotal,
  &__vat,
  &__fee {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;

    &--label {
      min-width: 55px;
      font: var(--elo-body-xs-regular);
      color: var(--color-primary-dg-400);
    }

    &--date {
      font: var(--elo-body-xs-medium);
      color: var(--color-primary-dg-brand);
    }
  }

  &__vat {
    margin-left: 16px;
  }

  &__fee {
    margin-bottom: 0;
  }

  &__separator {
    height: 1px;
    background-color: var(--color-primary-wg-brand);
    margin: 16px 0;
  }
}
