@import 'src/ui-kit/styles/colors';

.checkout-reseller-message {
  width: 100%;
  text-align: center;
  margin-top: 24px;
  color: $elo-black-semi;
  font: var(--elo-body-xs-regular);

  span {
    color: $elo-blue;
    text-decoration: underline;
  }
}
