@import 'src/ui-kit/styles/colors';
@import 'src/ui-kit/styles/fonts';
@import 'src/ui-kit/styles/elo-field';

.multiple-ticket-date-container {
  .multiple-ticket-date-field {
    width: 100%;

    &__control {
      box-sizing: border-box;
      line-height: 1.4;
      border: 1px solid #d9d9d9;
      border-radius: 4px;
      background-color: $elo-white;
      box-shadow: none;
      height: 40px;

      &:hover {
        border-color: #d9d9d9;
      }

      &--is-focused:not(&--error) {
        border-color: $elo-blue;
      }

      &--is-focused:hover,
      &--is-focused:not(:hover) {
        border-color: $elo-blue;
      }
    }

    &__value-container {
      padding: 10px 18px;
      line-height: 1.4;
    }

    &__placeholder {
      color: $elo-grey !important;
      font-size: var(--elo-font-size-sm) !important;
      margin: 0;
    }

    &__menu {
      margin-top: 0;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      box-shadow: 0 3px 15px 0 #00000033;
      z-index: 10000;
    }

    &__menu-list {
      max-height: 200px;
      padding-top: 0;
      padding-bottom: 0;
    }

    &__dropdown-indicator {
      padding-top: 4px;
      padding-right: 21px;
      font-size: var(--elo-font-size-md);
      color: var(--select-indicator-font-color);

      &--arrow-up-position {
        padding-top: 8px;
      }
    }

    &__indicator-separator {
      display: none;
    }

    &__option {
      padding: 10px 12px;

      &--is-focused {
        background-color: #2684ff1a;
      }

      &--is-selected {
        background-color: $elo-blue;

        .cashout-option-label__provider {
          color: $elo-white;
        }
      }
    }

    &--max-with-400 {
      max-width: 400px;
    }
  }
}
